import { DEFAULT_DIALOG_CONFIG, DialogConfig } from '@angular/cdk/dialog';
import { makeEnvironmentProviders } from '@angular/core';

import { DialogComponent } from './dialog.component';

const config: DialogConfig = {
  maxHeight: '90vh',
  maxWidth: '50rem',
  width: '100%',
  hasBackdrop: true,
  container: DialogComponent,
};

export const provideDialogs = () => {
  return makeEnvironmentProviders([
    {
      provide: DEFAULT_DIALOG_CONFIG,
      useValue: config,
    },
  ]);
};
