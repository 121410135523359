import {
  CdkDialogContainer,
  DialogModule,
  DialogRef,
} from '@angular/cdk/dialog';
import { Component, inject } from '@angular/core';
import { L10nModule } from '@features/l10n';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  standalone: true,
  imports: [DialogModule, L10nModule],
})
export class DialogComponent extends CdkDialogContainer {
  protected ref = inject(DialogRef);
}
