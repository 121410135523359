import {
  ApplicationConfig,
  DEFAULT_CURRENCY_CODE,
  LOCALE_ID,
  provideZoneChangeDetection,
} from '@angular/core';
import {
  provideRouter,
  withComponentInputBinding,
  withInMemoryScrolling,
  withRouterConfig,
  withViewTransitions,
} from '@angular/router';
import { provideAnimations } from '@angular/platform-browser/animations';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { DEFAULT_DIALOG_CONFIG } from '@angular/cdk/dialog';

import { environment } from '@environment/environment';
import { provideApi } from '@features/api';
import { provideCheckout } from '@features/checkout';
import { provideAuth, tokenInterceptor } from '@features/auth';
import { languageInterceptor, provideTranslations } from '@features/l10n';

import { routes } from './app.routes';
import { provideDialogs } from '@ui/components/dialog/provide-dialogs';

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideApi({ url: environment.api_url }, [
      tokenInterceptor,
      languageInterceptor,
    ]),
    provideRouter(
      routes,
      withInMemoryScrolling({ scrollPositionRestoration: 'top' }),
      withRouterConfig({ paramsInheritanceStrategy: 'always' }),
      withViewTransitions(),
      withComponentInputBinding(),
    ),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAnimations(),
    provideDialogs(),
    provideAuth(),
    provideCheckout(
      (id, key) =>
        `${environment.checkout_url}/${id}?pay_for_order=true&key=${key}`,
    ),
    provideTranslations(['en', 'es']),
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'USD',
    },
    {
      provide: LOCALE_ID,
      useValue: 'en-GB',
    },
  ],
};
