export const environment = {
  api_url: 'https://api-fxify-development.stagingservers.co/api',
  firebase: {
    apiKey: 'AIzaSyBwqadj31P7CXnP1475WiRA9sIcFe8MyOY',
    authDomain: 'fxify-b6a62.firebaseapp.com',
    projectId: 'fxify-b6a62',
    storageBucket: 'fxify-b6a62.firebasestorage.app',
    messagingSenderId: '212849859852',
    appId: '1:212849859852:web:a52648b8ac96f333163d29',
    measurementId: 'G-DL8WNBLK7P',
  },
};
